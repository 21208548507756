<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <v-icon
            dark
            small
          >
            {{ formattedItem.avatar }}
          </v-icon>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        v-html="formattedItem.title"
      />
      <v-list-item-subtitle
        v-html="formattedItem.subtitle"
      />
      <v-list-item-subtitle
        v-if="formattedItem.extraSubtitle"
        v-html="formattedItem.extraSubtitle"
      />
    </v-list-item-content>
    <v-list-item-action
      v-if="!readonly"
    >
      <v-btn
        icon
        @click.stop="clickDelete"
      >
        <v-icon>{{ $vuetify.icons.values.delete }}</v-icon>
      </v-btn>
    </v-list-item-action>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { TIEMPO_TRABAJADO } from '@/utils/consts'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      let fechaHoraFin
      // si es el mismo día no pongo el día en ffin
      if (this.$options.filters.shortDate(item.finicio) === this.$options.filters.shortDate(item.ffin)) {
        fechaHoraFin = this.$options.filters.shortTime(item.ffin)
      } else {
        fechaHoraFin = this.$options.filters.shortDateTime(item.ffin)
      }
      item.title = `${this.$options.filters.shortDateTime(item.finicio)} - ${fechaHoraFin}`
      item.subtitle = this.$options.filters.humanizeDuration(item.finicio.getTime() - item.ffin.getTime())
      if (item.idparte_trabajo) {
        item.extraSubtitle = `Parte de trabajo Nº ${item.idparte_trabajo}`
      } else if (item.observaciones != null) {
        item.extraSubtitle = item.observaciones
      }
      if (item.es_desplazamiento) {
        item.avatar = this.$vuetify.icons.values.desplazamiento
        item.avatarColor = TIEMPO_TRABAJADO.colores.desplazamiento
      } else {
        item.avatar = this.$vuetify.icons.values.manoDeObra
        item.avatarColor = TIEMPO_TRABAJADO.colores.manoDeObra
      }
      return item
    }
  },
  methods: {
    clickDelete () {
      this.$emit('click-delete', { item: this.item, index: this.index })
    },
  },
}
</script>
